@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Cera Round Pro";
	src: url("./fonts/ceraroundpro-regular.otf") format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: "Cera Round Pro";
	src: url("./fonts/ceraroundpro-bold.otf") format("opentype");
	font-weight: 700;
}

@font-face {
	font-family: "Cera Round Pro";
	src: url("./fonts/ceraroundpro-black.otf") format("opentype");
	font-weight: 900;
}

::selection {
	@apply bg-blue-700 text-white;
}

body {
	@apply bg-light-500;
}
